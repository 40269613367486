/*
 * Copyright 2019 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { resetViewport } from '../client/actions';
import { ResetViewportAction } from '../client/types';
import { ThunkCommandFactory } from '../types';
import * as actions from './actions';
import { ServerInfo, ServerInfoAction } from './types';

export const serverInfo: ThunkCommandFactory<ServerInfo, ServerInfoAction | ResetViewportAction> = (
  (info) => (dispatch) => {
    dispatch(resetViewport());
    dispatch(actions.serverInfo(info));
  });
